.os * {
    font-size: 13px;
}


.os .ant-descriptions-header {
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    white-space: pre-line;
    margin: 0;
    border: 1px solid rgba(0,0,0,.85);
    border-bottom: none;
}


.os .ant-descriptions-header,
.os .ant-descriptions-bordered .ant-descriptions-item-content, 
.os .ant-descriptions-bordered .ant-descriptions-item-label {
    padding: 10px 10px;
}

.os .ant-descriptions-title {
    white-space: pre-line;
    font-weight: normal;
}

.os .ant-descriptions-item-label,
.os .ant-descriptions-item-content {
    vertical-align: initial;
}


.os .ant-descriptions-item-label,
.os .ant-descriptions-item-content {
    font-weight: normal;
}

.os {
    margin-top: 20px;
}

.os .ant-descriptions {
    width: 100%;
    white-space: pre-wrap;
}
.os .ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid rgba(0,0,0,.85);
}

.os .ant-descriptions-bordered .ant-descriptions-item-content{
    border-left: 1px solid rgba(0,0,0,.85);
}

.os .ant-descriptions-bordered .ant-descriptions-item-label{
    border-right: none;
}


.os .ant-descriptions-bordered .ant-descriptions-view {
    border: 1px solid rgba(0,0,0,.85);
}