.ticket * {
    font-size: 13px;
}

.ticket .ant-descriptions-header,
.ticket .ant-descriptions-bordered .ant-descriptions-item-content, 
.ticket .ant-descriptions-bordered .ant-descriptions-item-label {
    padding: 10px 10px;
}

.ticket .ant-descriptions-header {
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    white-space: pre-line;
    margin: 0;
    border: 1px solid rgba(0,0,0,.85);
    border-bottom: none;
}

.ticket img {
    max-width: 50%;
}

.ticket .ant-descriptions-title {
    white-space: pre-line;
    font-weight: normal;
}


.ticket .ant-descriptions-item-label,
.ticket .ant-descriptions-item-content {
    vertical-align: initial;
}


.ticket .ant-descriptions-item-label,
.ticket .ant-descriptions-item-content {
    font-weight: normal;
    word-wrap: break-word;
}

.ticket {
    margin-top: 20px;
}

.ticket .ant-descriptions {
    width: 100%;
    white-space: pre-wrap;
}

.ticket .ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1px solid rgba(0,0,0,.85);
}

.ticket .ant-descriptions-bordered .ant-descriptions-item-content{
    border-left: 1px solid rgba(0,0,0,.85);
}

.ticket .ant-descriptions-bordered .ant-descriptions-item-label{
    border-right: none;
}


.ticket .ant-descriptions-bordered .ant-descriptions-view {
    border: 1px solid rgba(0,0,0,.85);
}