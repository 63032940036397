.not-found h1 {
    font-weight: 400;
    font-size: 16px;
}

.not-found h1 a {
    text-decoration: underline;
    color: black;
}

.not-found h1 a:hover {
    opacity: 0.8;
}

.not-found {
    width: fit-content;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}