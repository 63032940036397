* {
  font-family: 'Sora', sans-serif;
  margin: 0px;
  padding: 0px;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-autocomplete {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.margin-top-search {
  margin-top: -95px;
  row-gap: 5px !important;
}

.search {
  background:url("./commons/icons/marcaSankhya.png") no-repeat; 
  background-size: 40% 70%;
  background-position: right bottom;
  height: 100%;
  width: 100%;
}

.App-home {
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-col-24 {
  display: flex;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-image-mask {
  display: none;
}

.icon-menu {
  display: flex;
  margin-top: 35px;
  margin-right: 30px;
  justify-content: end;
}

.ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.h1-search {
  display: flex !important;
  caret-color: transparent;
}

.ant-layout-header {
  padding: 0 20px !important;
}

.align-menu {
  justify-content: end;
  display: flex;
  align-items: center;
}

.access-denyied {
  background:url("./commons/icons/restricted-area.png") center no-repeat; 
  background-size: 100px;
  height: 100%;
  width: 100%;
  
}

.content-access {
  min-height: 180px;
  height: 100%;
}

.msg-alert{ 
  font-size:16px;
}