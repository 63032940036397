#section-filters {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#filters {
    width: 100%;
    height: 35px;
    
    background-color: transparent;
}

#content-filter {
    width: 100%;
    height: 100%;
    padding-left: 200px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

@media only screen and (max-width: 1400px) {
    #content-filter {
        padding-left: 150px;
    }
    #itens-filter-dropdown {
        padding-left: 150px !important;
    }
}

@media only screen and (max-height: 650px) {
    .ant-dropdown-menu {
        max-height: 250px;
        overflow-y: scroll;
    }
}

#itens-filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.item-filter {
    cursor: pointer;
    margin-right: 40px;
}

.item-filter span {
    font-style: normal;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #2b3a54;
}

.item-filter img {
    margin-right: 5px;
}

#filter-dropdown {
    margin-left: 5%;
}

#filter-dropdown span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2B3A54;
}

.filter-dropdown img { 
    margin-left: 5px;
}

.filter-selected {
    color: #008561;
    border-bottom: 3px solid #008561;
    filter: invert(28%) sepia(98%) saturate(1563%) hue-rotate(76deg) brightness(80%) contrast(100%);
    transition: all 1s ease-out;
}

#itens-filter-dropdown {
    width: 100%;
    height: 35px;
    padding-left: 200px;
    background-color: #F1F1F1;
}

#content-itens-filter-dropdown {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.show-dropdown {
    display: block !important;
}

.dont-show-dropdown {
    display: none !important;
}

.item-filter-dropdown {
    margin-right: 30px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #2B3A54;
}

.item-filter-dropdown:hover {
    color: #008561;
}

.option-selected {
    font-weight: 600;
    margin-left: 4px;
}

#clear-filters {
    margin-left: 60px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: #626E82;
    cursor: pointer;
}

#clear-filters span {
    margin-left: 4px;
}

.subfilter-dropdown-selected {
    color: #008561;
    filter: invert(28%) sepia(98%) saturate(1563%) hue-rotate(76deg) brightness(80%) contrast(100%);
    transition: all 1s ease-out;
}

.font-size-filter {
    font-size: 13px;
}