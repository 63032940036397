
.search-result-item-card {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    width: 100%;
    margin: 0px;
    height: auto;
    padding-left: 8px;
    padding-bottom: 36px;
    font-family: 'Sora', sans-serif;
  }
  
  .header-search-result-item-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .search-result-item {
    padding-right: 12px;
    margin-right: 36px;
    height: 30px;
    display: flex;
    flex-direction: row;
  
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #626E82;
  }
  
  .search-result-item span {
    margin: auto;
    margin-left: 5px;
    text-align: center;
  }

  .search-result-item img {
    width: 18px;
    margin-left: 10px;
    filter: invert(28%) sepia(98%) saturate(1563%) hue-rotate(76deg) brightness(80%) contrast(100%);
  }
  
  .search-result-item:first-child {
    color: #157A00;
    background-color: #E7F6E0;
    border-radius: 20px;
  }
  
  .title-search-result-item-card {
    margin-top: 4px;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #2B3A54;
    cursor: pointer;
    -webkit-user-drag: none;
  }

  .title-search-result-item-card:hover {
    text-decoration: underline;
    color: #008561;
  }
  

  .content-search-result-item-card span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;

    margin-top: 0px;
  
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #626E82;
  }
  
  .content-search-result-item-card mark {
    font-style: normal;
    font-weight: 600;
    color: #008561;
    background-color: transparent;
  } 