.icon-title-modal {
    margin-right: 10px;
}

.ant-modal-header {
    background: #e5f0ea !important;
    padding: 15px;
}

.ant-modal-body h3 {
    font-weight: 600;
    text-align: center;

}

.ant-modal-footer .ant-btn {
    font-weight: 600;
}

.ant-modal-footer .ant-btn.ant-btn-default {
    color: red;
}