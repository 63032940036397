.footer {
    display: flex;
    align-items: center;
    height: 60px;
    margin-top: auto;
}

.content-box {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 20px 0px 20px 0px;
}

.content-box .footer-link {
    color: #2B3A54;
    font-size: 11px;
    margin-right: 25px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

.content-box .footer-link:hover {
    text-decoration: underline;
}

.content-box .footer-link#sankhyaTrademark  {
    text-decoration: none;
    cursor: default;
}

footer a, footer a:hover {
    font-style: normal;
    font-weight: 600;
    color: #008561;
}

.footer.home {
    background-color: transparent;
    align-items: center;
    justify-content: center;
}

.footer.results,
.footer.visResult {
    background-color: #e9e9e9;
    align-items: center;
    justify-content: flex-start;
}

.content-box.results,
.content-box.visResult {
    padding-left: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

h1 {
    font-weight: 600;
}

p {
    margin-bottom: auto;
}

@media screen and (max-width: 1400px) {
    .content-box.results,
    .content-box.visResult {
        padding-left: 150px;
    }
}