
.ant-select-selector.page-home {
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border-radius: 70px;
  display: flex;
  align-items: center;
  text-indent: 40px;
  background-color: #fff;
}
  
.ant-select-selector.page-results {
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  background-color: #fff;
  border-radius: 70px;
  display: flex;
  align-items: center;
  text-indent: 20px;
}

.ant-select-selector.has-suggestion {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  transition-delay: 0.1s;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
}

.ant-select-selector.border-radius-focus {
  border-bottom-right-radius: 70px;
  border-bottom-left-radius: 70px;
  transition-property: all;
  transition-delay: 0.2s;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  align-items: center;
  display: flex;
}

.ant-input {
  text-indent: 8px;
  height: 45px;
  border-radius: 100px;
}

.ant-input-affix-wrapper {
  padding: 0px 0px;
  display: contents !important;
}

.ant-form-item-explain-error {
  margin-left: 25px;
}

.Search-button-border-radius {
  border-radius: 70px !important;
  width: 110px !important;
  height: 45px !important;
  background: rgba(0,133,97,1);
  font-weight: bold
}

.Search-button-border-radius:hover {
  background: rgba(0,133,97,1);
  color: white;
}

.ant-select-item-option-content {
  white-space: normal;
}

.layout-selection {
  background-color:transparent;
}

.ant-select-dropdown {
  z-index: 999;
  margin-top: -10px !important;
}