@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

a.openInNewTab {
    color: #157a00;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

a.openInNewTab:hover {
    text-decoration: underline;
}

.result-card-header-title > h1{
    font-weight: 800;
    font-size: 24px;
}

img.iconImg{
    height: 1em;
    width: 1em;
    margin-right: 5px;
    margin-bottom: 2px;
}


.ant-tag {
    background-color: transparent;
    border: none;
  }

.type-doc-tag {
    background-color: #e7f6e0;
    color: #157A00;
    border-radius: 40px;
}

.type-doc-icon {
    color: #157A00;
    height: 1em;
    width: 1em;
}