@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');


.help-center {
    margin-top: 20px;
    width: 100%;
    line-height: 1.6;
    word-wrap: break-word;
    font-family: 'Sora', sans-serif !important;
}

table {
    width: 50%;
    table-layout: fixed;
}

.help-center table td *,
.help-center table th * ,
.help-center table td{
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.help-center img {
    max-width: 100%;
}

.help-center p {
    margin-top: 1em !important;
}

.help-center [class*="color-red"] {
    color: red;
}

.help-center [class*="font-size-large"] {
    font-size: large;
}


.help-center a {
    text-decoration: underline;
    color: rgb(0, 64, 74);
    font-weight: 600;
}

.help-center pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.help-center hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #eee !important;
    background-color: transparent !important;
}

.help-center ul {
    list-style-type: disc;
}

.help-center ul, .help-center ol {
    padding-left: 20px;
    list-style-position: outside;
    margin: 20px 0 20px 20px;
}