.related-docs {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px
}

.related-docs > span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.related-docs > span > h4{
    margin-left: 10px;
    margin-bottom: 0;
}

.related-docs > span > svg {
    color: #626E82;
    height: 1em;
    width: 1em;
}

.related-docs-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
}

.related-docs-list a {
    margin-top: 10px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #626E82;
}

.related-docs-list a:hover {
    opacity: 0.8;
} 