.page_container {
    position: relative;
}
  
.pdf_text_layer {
    position: absolute;
    text-align: initial;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    line-height: 1;
}
  
.pdf_text_layer > span {
    color:transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;
}
  
.pdf_text_layer > span::selection {
    background: #1890ff;

}