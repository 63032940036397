.card-content {
    padding-left: 200px;
    padding-top: 20px;
    width: 100%;
    font-size: 13px;
    font-family: 'Sora', sans-serif !important;
    background-color: #FFFFFF;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}   

@media screen and (max-width: 1400px) {
    .card-content {
        padding-left: 150px;
    }
}

.card {
    margin-bottom: 50px;
}

.doc-content {
    min-width: 820px;
    max-width: 820px;
    margin-right: 50px;
}

