.sections-modal {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.prominent-information-modal {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.ant-drawer li {
    margin-left: 15px;
}

.subitens {
    margin-left: 30px !important;
}
