#results-screen {
    margin: 0px;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

html, body {
    height: 100%;
}

#search-section {
    width: 100%;
    height: 75px;
    padding-left: 200px;
    padding-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
}

#search-section img {
    width: 130px;
    height: 132px;
    margin-left: -200px;
    margin-right: 45px;
    margin-top: 40px;
    z-index: 0;
}

#content-results-screen {
    padding-left: 185px;
    padding-top: 10px;
    width: 1025px;
    background-color: #FFFFFF;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1400px) {
    #content-results-screen {
        padding-left: 135px;
        width: 975px;
    }
    #search-section {
        padding-left: 150px;
    }
    #search-section img {
        margin-left: -154px;
        margin-right: 0px;
        margin-top: 40px;
    }
}

#informations-results-screen {
    padding-left: 10px;
    padding-bottom: 10px;
}

#total-results-informations-results-screen {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: #A2ABB9;
}

#search-suggestions {
    margin-top: 10px;
    display: flex;
    flex-direction: row;

    color: #6C778A;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}


#options-search-suggestions {
    display: flex;
    flex-direction: row;

    margin-left: 10px;
}

.option-search-suggestions {
    margin-right: 10px;
    cursor: pointer;
}

.option-search-suggestions mark {
    font-style: normal;
    font-weight: 600;
    color: #008561;
    background-color: transparent;
}

#cards-results-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#cards-results-screen h2{
    margin-left: 10px;
}

.ant-pagination {
    justify-self: center;
    align-self: center;
    margin-bottom: 50px;
    flex-direction: row;
    display: flex;
}

#pageInfo {
    justify-self: center;
    align-self: center;
    margin-top: 50px;
}

li.ant-pagination-item, 
li.ant-pagination-prev, 
li.ant-pagination-jump-prev, 
li.ant-pagination-jump-next, 
li.ant-pagination-next {
    margin-left: 0%;
    margin-right: 0%;
}

ul.ant-pagination > li.ant-pagination-item,
ul.ant-pagination > li.ant-pagination-prev > button.ant-pagination-item-link, 
ul.ant-pagination > li.ant-pagination-next > button.ant-pagination-item-link {
    border-radius: 40px;
}

ul.ant-pagination > li.ant-pagination-item:hover,
ul.ant-pagination > li.ant-pagination-item:hover > a,
ul.ant-pagination > li.ant-pagination-prev > button.ant-pagination-item-link:hover, 
ul.ant-pagination > li.ant-pagination-next > button.ant-pagination-item-link:hover {
    color: #008561;
    border-color: #008561;
}

ul.ant-pagination > li.ant-pagination-next > button.ant-pagination-item-link[disabled]:hover,
ul.ant-pagination > li.ant-pagination-prev > button.ant-pagination-item-link[disabled]:hover {
    border-color: #d9d9d9;
    color: rgba(0,0,0,.25);}

.ant-pagination-item-active,
.ant-pagination-item-active > a {
    border-color: #008561;
    color: #008561;
}

#loading {
    background-color: #FFFFFF;
    position: relative;
    z-index: 100;
    width: 100vw;
    min-height: 100px;
    display: none;
}

.fixed-nav {
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    width: 100% !important;
    z-index: 3;
    overflow-y: hidden;
    background-color: #F6F6F6 !important;
} 

.img-background {
    background:url("../../icons/line-logo-sankhya2.png") no-repeat;
    background-position-x: 0;
    background-position-y: 0;
    background-color: #F6F6F6;
    background-origin: border-box;
    height: auto;
    width: 100%;
}