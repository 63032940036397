.result-card-header #search-section {
    width: 100%;
    height: 75px;
    padding: 50px 0px 50px 200px;
}

@media screen and (max-width: 1400px) {
    .result-card-header #search-section {
        padding: 50px 0px 50px 150px;
    }
}

.result-card-header {
    position: sticky;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
}

#col-autocomplete-and-previous-page {
    display: flex;
    flex-direction: column;
}

.back-results {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: fit-content;
    padding: 0 0 0 5px;
}

.back-results:hover {
    opacity: 0.8;
}

.back-results > h2 {
    font-size: 13px;
    margin-left: 10px;
}